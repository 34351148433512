import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import Vue from "vue";
// Notification

import axios from "@axios";

export default function useProblemsList() {





  const refproblemListTable=ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "id", label: " الرقم",formatter: title,  sortable: true,},
    {
      key: "type",
      label: "النوع",
      sortable: true,
      formatter: title,
   
    },
    {
      key: "person_id",
      label: "المستفيد",
      sortable: true,
      formatter: title,
   
    },
    {
      key: "custom_number",
      label: "الرقم",
      formatter: title,
      sortable: true,
   
    },
    { key: "status", label: "الحالة",formatter: title,  sortable: true,},
    { key: "user_id", label: "الموظف",formatter: title,  sortable: true,},
    { key: "created_at", label: "التاريخ",formatter: title,  sortable: true,},
    { key: "body", label: "الشكوى",formatter: title,  sortable: true,},

    

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);


  const dataMeta = computed(() => {
    const localItemsCount = refproblemListTable.value
      ? refproblemListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refproblemListTable.value.refresh();
  };
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      
    ],
    () => {
      refetchData();
    }
  );
  const resolveUserStatusVariant = (status) => {
    if (status === "قيد الانتظار") return "danger";
    if (status === "مغلقة") return "success";
    if (status === "جديدة") return "warning";
    return "primary";
  };
  const replyComplains = (id) => {
    Vue.swal({
      title: ' الرد على الشكوى',
      input: 'textarea',
      showCancelButton: true,
      allowEscapeKey: false,

  allowOutsideClick: false,
    confirmButtonText: 'موافق',
         cancelButtonText: 'الغاء',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
      inputValidator: (value) => {
        return !value && 'يجب ان تدخل رد على الشكوى !!'
      },
     
     
    }).then(result => {
      if (result.value) {
      let user=JSON.parse(localStorage.getItem('userData'))
    
      
       
      store
      .dispatch("app-problems/replyComplains", {
        person_id:id,
       body: result.value,
       user_id: user.id ,
      })
      .then((response) => {
        Vue.swal({
          
  
          title: "تم الرد على الشكوى بنجاح",

          icon: "success",
    confirmButtonText: "موافق",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });

     
      })
      .catch(() => {
        Vue.swal({
          title: "حدثت مشكلة في استرجاع البيانات",
  icon: "error",
       
    confirmButtonText: "موافق",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
  
       
      });
      }
      
    })
  

   
  }
  const fetchListProblems = (ctx, callback) => {
  
    store
      .dispatch("app-problems/fetchListProblems" ,{search: searchQuery.value,
        page_size: perPage.value,
      page: currentPage.value,})
      .then((response) => {
        const { data } = response.data;
        const {  total } = response.data.meta;
        //console.log("complains",response.data)
        callback(data);
        totalUsers.value = total;
      })
      .catch(() => {
        Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",
           
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
  };





  return {
    replyComplains,
    fetchListProblems,
    resolveUserStatusVariant,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    refproblemListTable,
    
    refetchData,

  };
}
